import React from 'react';
import { GetServerSidePropsContext } from 'next';
import { getCookie } from 'cookies-next';
import LoginPageLayout from 'layouts/userForms/loginPageLayout';
import { track } from 'utils/functions/track';
import Route from 'next/router';
import { defaultServerSideProps } from 'utils/functions/serverSide';
export default function Page() {
  React.useEffect(() => {
    track.onPageEnter('Login', {
      pathname: Route.pathname
    });
  }, []);
  return <LoginPageLayout data-sentry-element="LoginPageLayout" data-sentry-component="Page" data-sentry-source-file="index.tsx" />;
}
export async function getServerSideProps(context: GetServerSidePropsContext) {
  return defaultServerSideProps(context);
}