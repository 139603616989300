import React from 'react';
import Grid from 'components/uiKit/grid';
import Card from 'components/uiKit/card';
import Link from 'components/customLink';
import AppLogo from 'assets/logo/appLogo';
import FooterLayout from 'components/footer';
import CustomLoginForm from '../customLogin/loginForm';
export default function LoginPageLayout() {
  return <>
            <Card color="blue" style={{
      minHeight: '100dvh',
      marginBottom: '-4rem',
      paddingBottom: '5rem',
      borderRadius: 0
    }} data-sentry-element="Card" data-sentry-source-file="index.tsx">
                <Grid horizontalAlgin="center" padding={['p-5']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                    <Link href="/" data-sentry-element="Link" data-sentry-source-file="index.tsx">
                        <AppLogo color="white" width="150px" height="38px" data-sentry-element="AppLogo" data-sentry-source-file="index.tsx" />
                    </Link>
                </Grid>
                <Grid horizontalAlgin="center" padding={['pt-2']} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                    <Grid style={{
          width: '50rem',
          maxWidth: 'calc(100dvw - 2rem)'
        }} data-sentry-element="Grid" data-sentry-source-file="index.tsx">
                        <CustomLoginForm originUrl="/" data-sentry-element="CustomLoginForm" data-sentry-source-file="index.tsx" />
                    </Grid>
                </Grid>
            </Card>
            <FooterLayout data-sentry-element="FooterLayout" data-sentry-source-file="index.tsx" />
        </>;
}